@import url("https://fonts.googleapis.com/css?family=Gothic+A1:regular,light");
.nav-link {
  font-size: 10px;
  letter-spacing: 4px; }

.header-image {
  width: 100%; }

.header-image-inset {
  position: absolute;
  left: 0;
  right: 0;
  top: 500px;
  margin: auto;
  opacity: .95;
  max-width: 40em;
  width: 50%; }
  @media only screen and (max-width: 1200px) {
    .header-image-inset {
      top: 400px; } }
  @media only screen and (max-width: 1000px) {
    .header-image-inset {
      top: 300px; } }

.home-link-image {
  max-width: 250px;
  min-width: 150px; }

@media only screen and (max-width: 500px) {
  .nav-bar-links a {
    font-size: 12.5px; } }

.footer {
  overflow: visible !important;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 10px;
  margin-top: 50px;
  font-size: 10pt;
  letter-spacing: 1pt; }
  .footer .footer-lattice {
    position: relative;
    width: 120%;
    margin-bottom: 5%;
    margin-top: -4.5%;
    margin-left: 25%; }
  .footer img {
    max-width: 120%; }
  .footer .top-row {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
    width: 80%; }
    .footer .top-row .center {
      max-width: 30em;
      margin-top: 0;
      padding-left: 1em;
      padding-right: 1em; }
    .footer .top-row .left .address {
      margin-bottom: 18px; }
    .footer .top-row .left .notice {
      max-width: 1000px;
      font-size: 0.8em;
      font-weight: bold;
      margin-right: 1px; }
    .footer .top-row .right .phone-number {
      font-size: 15pt;
      letter-spacing: 3pt;
      overflow: hidden;
      white-space: nowrap; }
      @media only screen and (max-width: 500px) {
        .footer .top-row .right .phone-number {
          font-size: 10pt; } }
  .footer .copyright {
    margin-bottom: 20px;
    width: 80%;
    text-align: center;
    padding-right: 3em; }

.base {
  background: red; }

.test {
  font-size: 20pt;
  color: blue; }

.about-papertini {
  margin: 130px 20% 50px 20%;
  max-width: 1000px;
  text-align: center; }
  @media only screen and (max-width: 750px) {
    .about-papertini {
      margin: 70px 20% 20px; } }
  @media only screen and (max-width: 600px) {
    .about-papertini {
      margin: 50px 20% 10px; } }
  .about-papertini .header {
    margin-bottom: 50px;
    font-size: 0.6em;
    letter-spacing: 4px; }
    @media only screen and (max-width: 750px) {
      .about-papertini .header {
        margin-bottom: 40px; } }
    @media only screen and (max-width: 600px) {
      .about-papertini .header {
        margin-bottom: 30px; } }
  .about-papertini .body {
    font-size: 4em;
    line-height: 0.75; }
    @media only screen and (max-width: 750px) {
      .about-papertini .body {
        font-size: 3em; } }
    @media only screen and (max-width: 600px) {
      .about-papertini .body {
        font-size: 2em; } }

.slider {
  overflow: visible;
  height: 700px !important; }

.slide {
  margin-left: -15px; }

.cross {
  position: relative;
  width: 100%;
  justify-content: center;
  margin-bottom: 10%; }
  .cross img {
    height: 40px; }

.flower-a {
  width: 40%;
  margin-bottom: -8em;
  margin-left: 25em; }

.flower-b {
  margin-top: -5%;
  margin-right: 60%;
  width: 20%;
  max-width: 225px;
  z-index: 2; }

.grandparent {
  overflow: visible !important; }
  .grandparent .slide {
    position: relative;
    padding-bottom: 5em;
    margin-left: 50px;
    height: 750px;
    width: auto;
    overflow: visible; }
    .grandparent .slide.A {
      background-color: #eac2af; }
      .grandparent .slide.A .lattice {
        position: absolute;
        width: 53%;
        top: 50%;
        left: -3%;
        transform: translateY(-50%);
        opacity: 0.8; }
    .grandparent .slide.B {
      background-color: #bee5df; }
      .grandparent .slide.B .lattice {
        position: absolute;
        width: 50%;
        bottom: 0;
        right: 0;
        transform: translate(2%, 10%);
        opacity: 0.8; }
    .grandparent .slide.C {
      background-color: #e5e5e3; }
      .grandparent .slide.C .lattice {
        position: absolute;
        width: 30%;
        min-width: 300px;
        top: 0;
        left: 0;
        transform: translate(-5%, -10%);
        opacity: 0.8; }
    @media only screen and (min-width: 770px) {
      .grandparent .slide {
        padding-top: 5em; } }
    @media only screen and (max-width: 770px) {
      .grandparent .slide {
        padding-top: 1em; } }
    .grandparent .slide .photocontainer {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin: auto; }
      @media only screen and (min-width: 800px) {
        .grandparent .slide .photocontainer {
          width: 75%; } }
    .grandparent .slide .centercontainer {
      display: flex;
      justify-content: center; }
    .grandparent .slide .tantiphoto {
      box-shadow: 10px 10px 9px #84300c33;
      max-width: 500px;
      z-index: 1; }
      @media only screen and (min-width: 770px) {
        .grandparent .slide .tantiphoto {
          width: 75%; } }
      @media only screen and (max-width: 770px) {
        .grandparent .slide .tantiphoto {
          width: 50%; } }
    .grandparent .slide .description {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media only screen and (max-width: 770px) {
        .grandparent .slide .description {
          width: 85%; } }
      .grandparent .slide .description .aboutteamtext {
        font-size: 3em; }
      .grandparent .slide .description .teampersonname {
        margin: 5px 0 5px 0;
        font-size: 1em;
        letter-spacing: 5px;
        text-transform: uppercase; }
      .grandparent .slide .description .jobtitle {
        text-transform: uppercase;
        font-size: 0.6em;
        letter-spacing: 4px; }
      .grandparent .slide .description .body {
        font-size: 0.7em;
        text-transform: uppercase;
        letter-spacing: 1.15px;
        line-height: 18px; }

.centerelement {
  margin: auto;
  width: 75%; }
  .centerelement .current-deal {
    font-size: 0.8em; }

.description {
  left: 40%;
  width: 75%; }

img {
  max-width: 100%;
  max-height: 100%; }

.carousel {
  position: relative; }

.carousel-arrow {
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 50%; }

.carousel-arrow.right {
  right: 1rem; }

.carousel-arrow.left {
  left: 1rem; }

.slider a.nextButton,
.slider a.previousButton {
  z-index: 3;
  opacity: 0.45;
  top: 90%;
  height: 7em; }
  @media only screen and (max-width: 770px) {
    .slider a.nextButton,
    .slider a.previousButton {
      height: 5em;
      opacity: 0.85; } }

.slider a.previousButton {
  transform: translateY(-50%) scale(0.9) rotate(180deg) !important;
  left: 5% !important; }

.slider a.previousButton:not(.disabled):hover {
  opacity: 0.8;
  z-index: 5;
  transform: translateY(-50%) scale(1.25) rotate(180deg) !important; }

.slider a.nextButton:not(.disabled):hover {
  z-index: 5;
  opacity: 0.8; }

.highlight-section .quote-container {
  display: flex;
  justify-content: center; }
  .highlight-section .quote-container .tantiquote {
    align-self: flex-end;
    padding-top: 2em;
    padding-bottom: 2em;
    max-width: 500px;
    min-width: 200px; }
    .highlight-section .quote-container .tantiquote .text {
      font-size: 4em;
      line-height: 0.75em; }
      @media (max-width: 768px) {
        .highlight-section .quote-container .tantiquote .text {
          font-size: 2.5em; } }

.highlight-section .tile-2 {
  display: flex; }

.highlight-section .tile-3 {
  padding-right: 10%; }

.highlight-section .tile-4 {
  margin-top: 20%; }

.highlight-section .tile-5 {
  padding-right: 10%;
  margin-top: -15em; }

.highlight-section .highlight-tile a {
  display: flex;
  justify-content: center;
  text-decoration: none; }

.highlight-section .highlight-tile .portfolio-event-card {
  color: black;
  display: inline-block;
  padding: 3%;
  margin-right: -10%; }
  @media (max-width: 768px) {
    .highlight-section .highlight-tile .portfolio-event-card {
      width: 75%;
      margin-right: 0; } }
  .highlight-section .highlight-tile .portfolio-event-card .portfolio-details {
    padding: 14% 0% 2% 5%;
    margin: -10% 16px 0 -16px;
    z-index: 2;
    display: inherit;
    width: 50%;
    min-width: 200px;
    opacity: 0.88; }

.highlight-section .flower-c {
  width: 300px;
  height: 200px;
  position: absolute;
  left: 175px;
  z-index: -1;
  top: -25%; }

.highlight-section .flower-d {
  max-height: 200px;
  max-width: 200px;
  margin-top: -10%;
  margin-bottom: -10%;
  margin-left: auto;
  z-index: -1; }

.center {
  margin: 55px 0 0 0;
  width: 60%;
  text-align: center; }

.instagram {
  letter-spacing: 5px; }

.scroll-arrow {
  top: -185px;
  background-color: #ffffff60;
  padding: 1em;
  border-radius: 2em;
  height: 125px;
  position: relative;
  float: right;
  margin-right: 2em;
  transition: all 0.3s linear; }
  .scroll-arrow:hover {
    transform: scale(1.2); }

.instagram-slider {
  margin: 30px 0px 75px 0px;
  height: 230px;
  text-align: center; }
  .instagram-slider ::-webkit-scrollbar {
    display: none; }
  .instagram-slider .instagram-photos {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap; }
    .instagram-slider .instagram-photos .spacer {
      width: 30px;
      display: inline-block; }

.instagram-photos img {
  margin: 0 8px 8px 0;
  width: 230px;
  height: 230px;
  object-fit: cover; }

.svg-curl {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
  padding: 5px; }

@media only screen and (max-width: 520px) {
  #tantiphoto {
    left: 50%;
    width: 40%;
    margin-top: 5%; } }

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto; }

.login-form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  width: 45%;
  max-width: 615px;
  min-width: 250px;
  margin: 50px auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.24); }

.login-form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px; }

.login-form button {
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer; }

.login-form button:hover,
.form button:active,
.form button:focus {
  background: #43a047; }

.login-form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px; }

.login-form .message a {
  color: #4caf50;
  text-decoration: none; }

.login-form .register-form {
  display: none; }

.contacts_flower02 {
  position: absolute;
  width: 200px;
  height: 200px;
  left: -3%;
  top: -35%; }

.pricing {
  margin-top: 70px;
  margin-bottom: 50px;
  max-width: 900px; }

.frame-photo {
  position: absolute;
  width: 225px;
  right: -3%;
  bottom: 14%; }

.frame01 {
  position: absolute;
  width: 375px;
  bottom: -2%; }

.flower_patch {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: -1;
  transform: translateY(-25%); }
  .flower_patch img {
    max-width: 900px;
    margin: 0 auto; }

.registration {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1; }

.base {
  background: red; }

.test {
  font-size: 20pt;
  color: blue; }

.contact-form-header {
  font-size: 12px;
  width: 400px; }

.contact-form-container {
  width: 90%;
  margin: 5% auto;
  max-width: 645px; }

.contact-form label {
  font-size: 15px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  width: 100%; }

.contact-form p {
  color: #e9aba3;
  font-size: 12px;
  letter-spacing: 1px;
  width: 100%; }

.contact-form .text-input {
  height: 28px;
  border-color: #e9aba3;
  border-style: solid;
  border-width: 1px;
  font-size: 12px; }

.contact-form ::placeholder {
  color: #e9aba3;
  opacity: 1;
  /* Firefox */
  font-size: 10px;
  letter-spacing: 2px; }

.contact-form .full-width {
  width: 100%; }

.contact-form .phone {
  width: 65px;
  text-align: center;
  margin-right: 15px; }

.contact-form .appt-date {
  color: #df8073;
  text-align: center;
  text-align-last: center;
  margin-right: 12px;
  margin-top: 12px; }

.contact-form .date-time-picker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 2em; }
  .contact-form .date-time-picker .time-picker {
    display: flex;
    flex-direction: row; }

.contact-form .month {
  width: 60px; }

.contact-form .day {
  width: 60px; }

.contact-form .year {
  width: 100px;
  margin-right: 40px; }

.contact-form .hour {
  width: 60px; }

.contact-form .minute {
  width: 60px; }

.contact-form .period {
  width: 45px; }

.contact-form .message {
  height: 150px; }

.contact-form .submit {
  background-color: #df8073;
  color: #ffffffdd;
  width: 100px;
  height: 28px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 2px;
  vertical-align: center;
  text-align: center; }

.contact-form .option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #df8073;
  font-size: 12px;
  letter-spacing: 1px; }

.contact-form .box {
  float: left;
  border-color: #df8073;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  height: 20px;
  width: 20px;
  margin-right: 15px; }

.contact-form .selected {
  background-color: #df8073; }

.panel {
  margin: 10px;
  min-height: 300px;
  padding: 40px 100px;
  display: flex;
  flex-flow: row wrap;
  /*flex-direction flex-wrap*/
  align-items: center;
  justify-content: center; }

.panel-text {
  width: 55%; }

@media only screen and (max-width: 800px) {
  .panel-text {
    width: 65%; }
  .panel {
    padding: 20px 50px; } }

@media only screen and (max-width: 750px) {
  .panel-text {
    width: 77%;
    padding: 0; } }

@media only screen and (max-width: 650px) {
  .panel-text {
    width: 90%;
    padding: 0; }
  .panel {
    padding: 15px 30px; } }

.panel-font-1 {
  margin-bottom: 14px; }

.panel-font-2 {
  margin-left: 42px;
  margin-bottom: 14px;
  font-size: 12px;
  line-height: 21px; }

.panel-font-2.sister-site > div {
  margin-bottom: 6px;
  font-size: 11px;
  letter-spacing: 1.15px; }

.panel-font-3 {
  margin-left: 42px;
  margin-bottom: 14px;
  font-size: 10px;
  line-height: 21px; }

.panel-font-4 {
  margin-left: 42px;
  margin-bottom: 14px;
  font-size: 10px;
  line-height: 21px; }

.panel-image {
  flex: 1 1 auto; }

.panel-image-src {
  margin: 0 auto;
  height: 450px;
  width: 250px;
  position: relative; }

.pink-tile-row {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative; }

.pink-tile {
  background-color: #f2d2c3;
  flex: 0 0 32%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  opacity: 0.8;
  padding-bottom: 6em; }
  .pink-tile .star {
    padding-top: 4em;
    padding-bottom: 2em; }
    .pink-tile .star img {
      max-height: 40px; }
  .pink-tile .pink-header {
    padding-bottom: 1em;
    font-size: 3em; }
  .pink-tile .pink-header + div {
    font-size: 14px;
    margin: 0 25%; }

@media only screen and (max-width: 720px) {
  .pink-tile-row {
    flex-direction: column; }
  .pink-tile {
    margin-bottom: 35px;
    padding: 0 0 30px 0px; }
    .pink-tile .star {
      padding-top: 1.5em;
      padding-bottom: 1.5em; }
    .pink-tile .pink-header {
      padding-bottom: 0.5em; }
    .pink-tile .pink-header + div {
      margin: 0 15%; } }

.base {
  background: red; }

.employee-portal {
  min-height: 200px;
  width: 80%;
  text-align: left;
  padding-top: 15px; }
  .employee-portal .event-form-link {
    color: #555;
    font-family: 'Gothic A1'; }
  .employee-portal .event-form-link.active {
    color: black;
    font-weight: bold;
    text-decoration: underline; }
  .employee-portal .event-form {
    color: #df8073; }
    .employee-portal .event-form .error {
      color: red; }
    .employee-portal .event-form .loading-indicator {
      position: fixed;
      /* Sit on top of the page content */
      width: 100%;
      /* Full width (cover the whole page) */
      height: 100%;
      /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      /* Black background with opacity */
      z-index: 2;
      /* Specify a stack order in case you're using a different order for other elements */
      cursor: pointer; }
    .employee-portal .event-form .text-fields-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
    .employee-portal .event-form .photo-section .photo-row {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 25px; }
      .employee-portal .event-form .photo-section .photo-row .P {
        height: 480px;
        width: 330px; }
      .employee-portal .event-form .photo-section .photo-row .L {
        height: 440px;
        width: 675px; }
      .employee-portal .event-form .photo-section .photo-row .image {
        min-height: 200px; }
        .employee-portal .event-form .photo-section .photo-row .image .close-button {
          position: absolute;
          display: inline;
          background-color: #ffffff99; }
    .employee-portal .event-form .photo-section .photo-cropper .add-photo {
      background-color: #ffffffdd;
      color: #df8073;
      border: 1px solid #df8073;
      height: 28px;
      font-weight: 300;
      font-size: 12px;
      letter-spacing: 2px;
      display: flex;
      padding: 10px;
      align-items: center;
      text-align: center; }
    .employee-portal .event-form .photo-section .photo-cropper .cropper .P {
      max-width: 480px; }
    .employee-portal .event-form .photo-section .photo-cropper .cropper .L {
      max-width: 675px; }
    .employee-portal .event-form .photo-section .photo-cropper .dropzone {
      align-items: center;
      border-color: #e9aba3;
      border-style: dashed;
      border-width: 2px;
      display: flex;
      justify-content: center; }
    .employee-portal .event-form .photo-section .photo-cropper .P {
      height: 480px;
      width: 330px; }
    .employee-portal .event-form .photo-section .photo-cropper .L {
      height: 440px;
      width: 675px; }
  .employee-portal .submit {
    margin-top: 2rem; }

.login-form.edit-user {
  width: unset; }

.create-user .add-photo {
  background-color: #ffffffdd;
  color: #000;
  border: 1px dashed #000;
  height: 50px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center; }

.edit-user .add-photo {
  background-color: #ffffffdd;
  color: #000;
  border: 1px dashed #000;
  height: 50px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 2px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center; }

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
  /* prevents background page from scrolling when the modal is open */ }

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center; }

.ReactModal__Content {
  background: red;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
  border: unset !important; }

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem; }

.portfolio {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .portfolio .search-container {
    padding-left: 6em;
    padding-bottom: 50px;
    padding-top: 15px;
    width: 100%; }
    .portfolio .search-container label {
      color: #999;
      font-family: 'Gothic A1';
      font-size: 13px;
      font-weight: 100;
      letter-spacing: 5px; }
    .portfolio .search-container .search-box {
      border: solid #999 1px;
      outline: none;
      color: #777; }
  .portfolio .thumbnail-container {
    max-width: 1050px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .portfolio .thumbnail-container .portfolio-ghost-card {
      z-index: 0;
      width: 310px;
      display: inline-block;
      height: 10px;
      padding: 10px;
      opacity: 0.88; }
    .portfolio .thumbnail-container .portfolio-event-card {
      z-index: 0;
      width: 310px;
      display: inline-block;
      padding: 10px; }
      .portfolio .thumbnail-container .portfolio-event-card .portfolio-thumbnail {
        object-fit: cover;
        height: 300px;
        max-width: 270px; }
      .portfolio .thumbnail-container .portfolio-event-card .portfolio-details {
        padding: 14% 0% 2% 5%;
        margin: -43px 16px 0 -16px;
        z-index: 2;
        display: inherit;
        width: 250px;
        opacity: 0.88; }
    .portfolio .thumbnail-container .click-more {
      margin: 25px auto 50px auto;
      width: 100%;
      text-align: center;
      cursor: pointer; }

.event-show {
  max-width: 850px; }
  .event-show .vendor-links {
    font-size: 0.8em;
    padding-bottom: 4em;
    font-weight: bold;
    text-transform: uppercase; }
    .event-show .vendor-links a {
      color: gray;
      font-weight: normal; }
  .event-show .photo-row {
    flex-wrap: unset;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 25px; }
    .event-show .photo-row .P {
      margin-right: 5px;
      height: 480px;
      width: 330px; }
    .event-show .photo-row .L {
      height: 440px;
      width: 675px; }
    .event-show .photo-row .image {
      min-height: 200px; }

@media only screen and (max-width: 620px) {
  .event-show {
    max-width: 850px; }
    .event-show .photo-row {
      display: flex;
      justify-content: space-evenly;
      padding-bottom: 25px; }
      .event-show .photo-row .P {
        height: unset;
        width: unset;
        max-height: 480px;
        max-width: 330px; }
      .event-show .photo-row .L {
        height: unset;
        width: unset;
        max-height: 440px;
        max-width: 675px; }
      .event-show .photo-row .image {
        min-height: 200px; } }

.portfolio a {
  color: inherit; }

.events-form {
  height: 100%; }

@font-face {
  font-family: 'Gotham Round Book';
  font-style: normal;
  font-weight: 350;
  src: local("GothamRnd Book"), local("GothamRnd-Book"), url("../fonts/GothamRnd-Book.otf") format("opentype"); }

@font-face {
  font-family: 'Gotham Round Light';
  font-style: normal;
  font-weight: 300;
  src: local("GothamRnd Light"), local("GothamRnd-Light"), url("../fonts/GothamRnd-Light.otf") format("opentype"); }

@font-face {
  font-family: 'Warm Script';
  font-weight: 400;
  src: local("WarmScript"), local("WarmScript-Regular"), url("../fonts/WarmScript-Regular.otf") format("opentype"); }

.gothic {
  font-family: 'Gotham Round Book'; }

.gothic-light {
  font-family: 'Gotham Round Light'; }

.script {
  font-family: 'Warm Script';
  font-size: 3em;
  line-height: 1em; }

.light {
  font-weight: 'light'; }

.redbox {
  border: 2px solid red; }

.bluebox {
  border: 2px solid blue; }

.greenbox {
  border: 2px solid green; }

.blackbox {
  border: 2px solid black; }

.app {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0;
  width: auto; }

html,
body {
  max-width: 100%;
  overflow-x: hidden; }

.panel {
  width: 90%;
  margin: 15px 5%; }

.panel.background-gray {
  background-color: #f4f3f1;
  position: relative; }

.contact-page {
  width: 100%; }
